.main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--neutral500);
}

.top-container {
  width: 1170px;
  max-width: 1170px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 32px;
  padding-bottom: 251px;
}

.bottom-container {
  width: 1170px;
  max-width: 1170px;
  padding-bottom: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 16px;
  background-color: white;
  padding: 32px;
  margin-bottom: 32px;
}

.discount {
  padding: 4px;
  border: 1px solid #dddcdc;
  background-color: var(--neutral200);
  height: 40px;
  width: 240px;
  box-shadow: inset 4px 4px 16px rgba(34, 34, 34, 0.1);
  display: flex;
  border-radius: 4px;
  justify-content: space-between;
  margin-top: 24px;
  position: relative;
  cursor: pointer;
}
.discount div {
  display: flex;
  justify-content: center;
  align-items: center;
}
.sliding-discount-button {
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: black;
  border-radius: 4px;
  padding: 0px 8px;
  height: calc(100% - 8px);
  transition: all 0.2s ease-in;
  overflow-y: hidden;
}

.plans {
  display: flex;
  gap: 16px;
  align-items: flex-end;
  width: 1082px;
  height: 550px;
  margin-top: 24px;
  margin-bottom: 16px;
}

.plans > div {
  flex: 1;
  border-radius: 16px;
  height: calc(100% - 34px);
  background-color: var(--neutral100);
  padding: 24px;
  display: flex;
  flex-direction: column;
  position: relative;
}

.plans > div:nth-child(2) {
  height: 100%;
}

.plans > div > div {
  display: flex;
  justify-content: space-between;
  height: fit-content;
  width: 100%;
}

.recommended {
  height: 20px;
  width: fit-content;
  border-radius: 10px;
  background-color: #c3ff0a;
  display: flex;
  align-items: center;
  padding-right: 8px;
  padding-left: 4px;
}

.price {
  display: flex;
  flex-direction: column;
  gap: 2px;
  margin-top: 16px;
}

.price > div {
  display: flex;
  align-items: flex-end;
  gap: 8.45px;
}

.rules {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
}

.rules > div {
  display: flex;
  padding: 16px 0px;
  border-bottom: 1px solid var(--neutral500);
}

.rules > div:first-child {
  padding-top: 0px;
}

.rules > div:last-child {
  padding-bottom: 0px;
  border-bottom: none;
}

.custom-plan {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  gap: 16px;
}
.custom-plan > div:first-child {
  display: flex;
  width: 100%;
  justify-content: center;
}

.plans-showcase-table {
  width: 100%;
  margin-top: 16px;
}

.plans-showcase-table th,
.plans-showcase-table td {
  width: 25%;
  padding-left: 16px;
  padding-right: 32px;
  padding-top: 16px;
  padding-bottom: 16px;
  border: 1px solid var(--neutral600);
}

.plans-showcase-table thead th {
  height: 100px;
}

.plans-showcase-table th > div {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.plans-showcase-table thead th > div {
  gap: 16px;
}
.plans-showcase-table thead th:first-child > div {
  gap: 8px;
}

.plans-showcase-table tbody th > div {
  gap: 4px;
  justify-content: center;
}

.plans-showcase-table thead th {
  border-top: none;
}
.plans-showcase-table thead th:first-child {
  border-left: none;
}
.plans-showcase-table thead th:last-child {
  border-right: none;
}

.plans-showcase-table tbody th {
  border-left: none;
  height: 80px;
}
.plans-showcase-table tbody td:last-child {
  border-right: none;
}
.plans-showcase-table tbody td > div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.scroll-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  background-color: #c3ff0a;
  height: 32px;
  width: fit-content;
  border-radius: 16px;
  border: none;
}

.divider {
  width: 100%;
  border-top: 1px solid var(--neutral500);
}

.main-page-content-container {
  padding: 16px 32px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.advertisement-banner {
  width: 100%;
  height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  background-color: black;
  padding: 8px 16px;
  border-radius: 4px;
}

.advertisement-banner > div {
  display: flex;
  gap: 8px;
  z-index: 1;
}

.advertisement-banner > div > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
}

.info-card-container {
  display: flex;
  gap: 24px;
}

.info-card {
  flex: 1;
  height: 168px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  position: relative;
  background-color: white;
  border: 1px solid var(--neutral500);
  border-radius: 4px;
}

.info-card > div:first-child {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.info-card > div:first-child > div:first-child {
  border: 1px solid var(--neutral500);
  padding: 4px;
  border-radius: 4px;
}

.info-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 104px;
  height: 32px;
  padding: 0px 8px;
  padding-right: 0px;
  border-radius: 4px;
  cursor: pointer;
  transition-property: background-color;
  transition-timing-function: ease-in;
  transition-duration: 0.2s;
  position: relative;
}
.info-details:hover {
  background-color: var(--neutral400);
}

.info-details-dropdown {
  position: absolute;
  top: 40px;
  box-shadow:
    0px 8px 12px rgba(9, 30, 66, 0.15),
    0px 0px 1px rgba(9, 30, 66, 0.31);
  padding: 8px;
  width: 331px;
  border-radius: 8px;
  background-color: white;
  z-index: 10;
  display: flex;
  flex-direction: column;
  gap: 8px;
  cursor: default;
}
.info-details-dropdown > div:last-child > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 8px;
  padding-right: 16px;
  height: 40px;
}
.info-details-dropdown > div:last-child > div > div:first-child {
  display: flex;
  align-items: center;
  gap: 8px;
}

.plan-title {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.plan-title > div:first-child {
  display: flex;
  align-items: center;
  gap: 4px;
}

.growth-plan-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--neutral500);
  padding: 16px;
  border-radius: 8px;
}

.sidebar-ads {
  border: 1px solid var(--neutral400);
  margin: 8px;
  border-radius: 4px;
  padding: 12px 8px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 152px;
}
.sidebar-ads > div:first-child {
  width: fit-content;
  height: 16px;
  padding: 0px 8px;
  display: flex;
  align-items: center;
  background-color: var(--neutral900);
  border-radius: 4px;
}

.request-modal {
  width: 100%;
  padding: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.close-btn {
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--neutral500);
  border-radius: 4px;
  position: absolute;
  right: 8px;
  top: 8px;
  background-color: white;
}

.requesting {
  position: relative;
}
.requesting > div {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 16px;
  bottom: -63px;
}

.loader {
  rotate: 0deg;
  animation-name: rotate;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
}

@keyframes rotate {
  from {
    rotate: 0deg;
  }

  to {
    rotate: 360deg;
  }
}

.settings-plans-info {
  width: 100%;
  height: 300px;
  padding: 16px;
  background-color: white;
  border: 1px solid var(--neutral500);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.settings-plans-info > div:nth-child(2) {
  width: fit-content;
  height: 16px;
  padding: 0px 8px;
  display: flex;
  align-items: center;
  background-color: var(--neutral900);
  border-radius: 4px;
}
.settings-plans-info > div:nth-child(3) {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.settings-plans-info > div:nth-child(4) {
  height: 96px;
  border-radius: 4px;
  background-color: var(--neutral400);
  padding: 12px;
}
.settings-plans-info span {
  font-family: GothamMedium;
  color: var(--neutral900);
}

.company-limit {
  height: 20px;
  display: flex;
  gap: 4px;
  align-items: center;
  padding: 0px 8px;
  background-color: #b9fc00;
  border-radius: 4px;
}
.company-limit_reached {
  height: 20px;
  display: flex;
  gap: 4px;
  align-items: center;
  padding: 0px 8px;
  border: 1px solid #e73126;
  border-radius: 4px;
  background-color: #ffeeee;
}

.upgrade-limit-banner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 56px;
  padding: 0px 18px 0px 12px;
  margin-top: 16px;
  border: 1px solid #e73126;
  border-radius: 4px;
  background-color: #ffeeee;
}
.upgrade-limit-banner > div:nth-child(1) {
  display: flex;
  gap: 12px;
  align-items: center;
}
.upgrade-limit-banner > div:nth-child(1) > div:nth-child(1) {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.upgrade-limit-banner > div:nth-child(2) {
  display: flex;
  gap: 8px;
  align-items: center;
  cursor: pointer;
}

.prevention-modal {
  width: 100%;
  padding: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.summary-limit {
  background-color: pink;
  height: 31px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  gap: 4px;
}
.summary-limit > div:last-child {
  font-family: GothamMedium;
  font-size: 12px;
  line-height: 16px;
  color: white;
  display: flex;
  height: 20px;
  padding: 0px 8px;
  align-items: center;
  background-color: var(--neutral900);
  border-radius: 4px;
}

.success-advertisement {
  display: flex;
  background-color: black;
  position: relative;
  height: 64px;
  width: 100%;
  padding-left: 16px;
  align-items: center;
  border-radius: 4px;
  gap: 8px;
  margin-bottom: 32px;
}
.success-advertisement > div {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 10;
}

.one-time-seed-modal {
  width: 100%;
  padding: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.one-time-seed-modal > div:last-child {
  width: 100%;
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.suspended-banner {
  display: flex;
  gap: 12px;
  align-items: center;
  height: 44px;
  padding-left: 12px;
  background-color: #fff6e6;
  border: 1px solid #fcb037;
  border-radius: 4px;
  margin: 24px 32px 28px 32px;
}

.draft-selection-modal {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}
.draft-selection-modal > .header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid var(--neutral500);
}
.draft-selection-modal > .content {
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-height: 450px;
  overflow-x: hidden;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.draft-selection-modal > .content::-webkit-scrollbar {
  display: none;
}
.draft-selection-modal .draft-table {
  width: 100%;
  padding: 8px;
  border: 1px solid var(--neutral500);
  border-radius: 4px;
}
.draft-selection-modal .draft-table table {
  width: 100%;
}
.draft-selection-modal .draft-table table thead tr {
  border-bottom: 1px solid var(--neutral500);
}
.draft-selection-modal .draft-table table thead th {
  height: 40px;
}
.draft-selection-modal .draft-table table tbody tr {
  border-bottom: 1px solid var(--neutral500);
}
.draft-selection-modal .draft-table table tbody td {
  height: 64px;
}
.draft-selection-modal .footer {
  width: 100%;
  padding: 20px;
  display: flex;
  gap: 20px;
  border-top: 1px solid var(--neutral500);
}
