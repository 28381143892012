.category-onboard-modal {
  min-width: 516px;
}

.category-onboard-content {
  display: flex;
  flex-direction: column;
  padding: 20px;
  padding-bottom: 32px;
}

.link {
  font-family: GothamMedium;
  text-decoration: underline;
  cursor: pointer;
}
.link:hover {
  color: var(--neutral800);
}
