.mainContainer {
  width: 100%;
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-color: var(--neutral400);
  border-radius: 4px;
}

.update {
  display: flex;
  gap: 8px;
  position: relative;
}
.iconContainer {
  width: 16px;
  min-width: 16px;
  height: 100%;
  min-height: 100%;
  position: relative;
  z-index: 10;
}
.contentContainer {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.progressLine {
  position: absolute;
  left: 7px;
  top: 12px;
  height: calc(100% + 8px);
  border-left: 1px solid var(--neutral600);
  border-right: 1px solid var(--neutral600);
}

.seeContainer {
  display: flex;
  padding-left: 24px;
  padding-top: 8px;
  align-items: center;
  border-top: 1px solid var(--neutral500);
}
