.retryResubmitModal {
  min-width: 560px;
}

.content {
  width: 100%;
  padding: 20px 20px 32px 20px;
}

.articleLink {
  cursor: pointer;
  text-decoration: underline;
}
.articleLink:hover,
.articleLink:active {
  color: var(--neutral800);
}
