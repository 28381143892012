.wrapper {
  position: relative;
}

.contactUsContainer {
  display: flex;
  gap: 8px;
  align-items: center;
  cursor: pointer;
}
