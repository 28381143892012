.category-selection {
  display: flex;
  gap: 8px;
  position: relative;
  margin-bottom: 32px;
}

.category-selection_simple {
  width: 236px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
  margin-bottom: 32px;
}

.category-selection_details {
  display: flex;
  gap: 8px;
  position: relative;
  margin-bottom: 32px;
}

.label {
  display: flex;
  align-items: center;
  gap: 4px;
}

.input {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 16px;
  border: 1px solid var(--neutral500);
  border-radius: 4px;
  cursor: pointer;
}
.input .left-section {
  height: 58px;
  display: flex;
  align-items: center;
  gap: 16px;
}
.input .text-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.input .title {
  display: flex;
  align-items: center;
}

.new-tag {
  height: 16px;
  display: flex;
  align-items: center;
  padding: 0px 8px;
  background-color: var(--greenB9);
  border-radius: 4px;
}

.selectionModal {
  min-width: 516px;
}
