/*
*
* ANIMATION TIMINGS
*
* Init (Simultaneously):
* 1. badgeOneReveal 0.4s (1.2s delay)
* 2. badgeTwoReveal 0.4s (1.2s delay)
* 3. badgeThreeReveal 0.4s (1.2s delay)
* 4. badgeFourReveal 0.4s (1.2s delay)
*
* Scroll Down (Simultaneously):
* 1. submitButtonEnlarge 1s
* 2. firstSectionVanish 1s
* 3. slidesContainerReveal 2s
* 4. badgeOneVanish 0.4s (1s delay)
* 5. badgeTwoVanish 0.4s (1s delay)
* 6. badgeThreeVanish 0.4s (1s delay)
* 7. badgeFourVanish 0.4s (1s delay)
* 8. optionsReveal 0.4s (2s delay)
* 9. imageOneReveal 0.4s (2s delay)
* 10.imageTwoReveal 0.4s (2s delay)
* 11.imageThreeReveal 0.4s (2s delay)
* 12.backToTopButtonReveal 0.4s (2s delay)
*
* Scroll Up (Simultaneously):
* 1. submitButtonReduce 1s
* 2. optionsVanish 0.4s
* 3. slidesContainerVanish 1s
* 4. imageOneVanish 0.4s
* 5. imageTwoVanish 0.4s
* 6. imageThreeVanish 0.4s
* 7. backToTopButtonVanish 0.4s
* 8. firstSectionReveal 1s (0.2s delay)
* 9. badgeOneReveal 0.4s (1.2s delay)
* 10.badgeTwoReveal 0.4s (1.2s delay)
* 11.badgeThreeReveal 0.4s (1.2s delay)
* 12.badgeFourReveal 0.4s (1.2s delay)
*
* Others:
* 1. Option selections 0.4s
* 2. Sliding images 0.4s
*
*/

.mainContainer {
  position: relative;
  width: 100%;
  height: 100%;
  min-width: 100%;
  min-height: 100%;
  max-width: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}

.backgroundContainer {
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
  min-width: 1440px;
  min-height: 100%;
  max-width: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  --nr: 10; /* number of rows */
  --nc: 18; /* number of columns */
  --b: 2px; /* border length */
  background: conic-gradient(
      from 90deg at var(--b) var(--b),
      black 90deg,
      #412e09 0
    )
    calc(-1 * var(--b)) calc(-1 * var(--b)) / calc(100% / var(--nc))
    calc(100% / var(--nr));
}
.movingLayer {
  position: absolute;
  top: calc(50% - 5000px);
  left: calc(50% - 5000px);
  z-index: 0;
  width: 10000px;
  height: 10000px;
  box-shadow: inset 0 0 4700px 4700px #000;
  background: transparent;
  animation-iteration-count: infinite;
  animation-name: movingLayer;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-duration: 20s;
}
@keyframes movingLayer {
  0% {
    translate: 0px;
  }
  10% {
    translate: -50vw 50vh;
  }
  20% {
    translate: 50vw 50vh;
  }
  30% {
    translate: 0px;
  }
  40% {
    translate: -50vw -50vh;
  }
  50% {
    translate: 50vw -50vh;
  }
  60% {
    translate: 0px;
  }
  70% {
    translate: -50vw 50vh;
  }
  80% {
    translate: 50vw 50vh;
  }
  90% {
    translate: 50vw -50vh;
  }
  100% {
    translate: 0px;
  }
}

.successRequestContainer {
  position: absolute;
  top: 0;
  z-index: 20;
  width: 100%;
  height: 100%;
  min-width: 1440px;
  min-height: 100%;
  max-width: 100%;
  max-height: 100%;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  transition-property: display;
  transition-duration: 1s;
}
.successRequestContainerReveal {
  display: flex;
}
.successRequestContainerVanish {
  display: none;
}
.successRequestTextContainer {
  position: relative;
  bottom: -100%;
  width: 652px;
  display: flex;
  flex-direction: column;
  align-items: center;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 1s;
}
.successRequestTextContainerReveal {
  animation-name: successRequestTextContainerReveal;
}
.successRequestTextContainerVanish {
  animation-name: successRequestTextContainerVanish;
  animation-delay: 0s;
  animation-duration: 0.4s;
}
@keyframes successRequestTextContainerReveal {
  0% {
    bottom: -100%;
  }
  100% {
    bottom: 0%;
  }
}
@keyframes successRequestTextContainerVanish {
  0% {
    bottom: 0%;
    opacity: 1;
  }
  100% {
    bottom: 0%;
    opacity: 0;
  }
}
.successRequestCloseButton {
  width: 127px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  padding-left: 16px;
  padding-right: 16px;
  background-color: #414041;
  border: none;
  transition-duration: 0.4s;
  transition-property: background-color;
}
.successRequestCloseButton:hover {
  background-color: #6d6d6d;
}
.successRequestImageContainer {
  position: relative;
  top: -100%;
  width: 300px;
  height: 300px;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 1s;
}
.successRequestImageContainerReveal {
  animation-name: successRequestImageContainerReveal;
}
.successRequestImageContainerVanish {
  animation-name: successRequestImageContainerVanish;
  animation-delay: 0s;
  animation-duration: 0.4s;
}
@keyframes successRequestImageContainerReveal {
  0% {
    top: -100%;
  }
  100% {
    top: 0%;
  }
}
@keyframes successRequestImageContainerVanish {
  0% {
    top: 0%;
    opacity: 1;
  }
  100% {
    top: 0%;
    opacity: 0;
  }
}

.mainContentContainer {
  position: relative;
  z-index: 2;
  width: 100%;
  height: 100%;
  min-width: 1440px;
  min-height: 100%;
  max-width: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  animation-fill-mode: forwards;
  animation-duration: 0.4s;
  animation-timing-function: ease-out;
}
.mainContentContainerFadeOut {
  animation-name: mainContentContainerFadeOut;
}
.mainContentContainerFadeIn {
  animation-name: mainContentContainerFadeIn;
}
@keyframes mainContentContainerFadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes mainContentContainerFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.firstSection {
  position: relative;
  z-index: 2;
  top: 0;
  width: 100%;
  height: 100%;
  min-width: 1440px;
  min-height: 100%;
  max-width: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
}
.firstSectionReveal {
  animation-name: firstSectionReveal;
  animation-timing-function: ease-in-out;
  top: -100%;
  animation-delay: 0.2s;
  animation-duration: 1s;
}
.firstSectionVanish {
  animation-name: firstSectionVanish;
}
@keyframes firstSectionReveal {
  0% {
    top: -100%;
  }
  100% {
    top: 0px;
  }
}
@keyframes firstSectionVanish {
  0% {
    top: 0;
  }
  100% {
    top: -100%;
  }
}
.secondSection {
  position: absolute;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  min-width: 1440px;
  min-height: 100%;
  max-width: 100%;
  max-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.titleContainer {
  width: 1000px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 20px;
  margin-bottom: 180px;
}
.mainTitle {
  width: 834px;
}

.submitButton {
  position: absolute;
  top: calc(50% - 230px / 2 + 160px);
  left: calc(50% - 230px / 2);
  z-index: 2000;
  width: 230px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 20px;
  padding-left: 16px;
  padding-right: 16px;
  background-color: #414041;
  text-wrap: nowrap;
  white-space: nowrap;
  border: none;
  overflow: hidden;
  transition-duration: 0.4s;
  transition-property: background-color;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
}
.underlineText {
  position: absolute;
  top: calc(50% - 120px / 2 + 160px);
  left: calc(50% - 230px / 2);
  z-index: 2000;
  width: 230px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: underline;
  cursor: pointer;
  text-wrap: nowrap;
  white-space: nowrap;
  border: none;
  overflow: hidden;
  opacity: 1;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

.submitButton:hover {
  background-color: #6d6d6d;
}
.submitButtonEnlarge {
  animation-name: submitButtonEnlarge;
}
.submitButtonReduce {
  animation-name: submitButtonReduce;
}
@keyframes submitButtonEnlarge {
  0% {
    width: 230px;
    top: calc(50% - 230px / 2 + 160px);
    left: calc(50% - 230px / 2);
  }
  100% {
    top: calc(50% - 230px / 2 - 250px);
    left: calc(50% - 692px / 2);
    width: 692px;
  }
}
@keyframes submitButtonReduce {
  0% {
    top: calc(50% - 230px / 2 - 250px);
    left: calc(50% - 692px / 2);
    width: 692px;
  }
  100% {
    top: calc(50% - 230px / 2 + 160px);
    left: calc(50% - 230px / 2);
    width: 230px;
  }
}

.scrollerContainer {
  width: 148px;
  height: 60px;
  position: absolute;
  top: calc(50% - 60px / 2 + 100px);
  left: calc(50% - 148px / 2);
  z-index: 2000;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  margin-top: 100px;
}
.scroller {
  animation-name: floatingMouse;
  animation-fill-mode: forwards;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}
@keyframes floatingMouse {
  0% {
    translate: 0px;
  }
  50% {
    translate: 0px 5px;
  }
  100% {
    translate: 0px;
  }
}

.badgeContainer {
  position: absolute;
  bottom: calc(50% - 80px);
  left: calc(50% - 80px);
  animation-timing-function: ease-out;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  opacity: 0;
}
.badgeOneReveal {
  animation-name: badgeOneReveal;
  animation-delay: 1.2s;
  opacity: 0;
}
.badgeTwoReveal {
  animation-name: badgeTwoReveal;
  animation-delay: 1.2s;
  opacity: 0;
}
.badgeThreeReveal {
  animation-name: badgeThreeReveal;
  animation-delay: 1.2s;
  opacity: 0;
}
.badgeFourReveal {
  animation-name: badgeFourReveal;
  animation-delay: 1.2s;
  opacity: 0;
}
.badgeOneVanish {
  animation-name: badgeOneVanish;
  animation-delay: 1s;
  opacity: 1;
  translate: -550px -250px;
}
.badgeTwoVanish {
  animation-name: badgeTwoVanish;
  animation-delay: 1s;
  opacity: 1;
  translate: -450px 110px;
}
.badgeThreeVanish {
  animation-name: badgeThreeVanish;
  animation-delay: 1s;
  opacity: 1;
  translate: 520px -190px;
}
.badgeFourVanish {
  animation-name: badgeFourVanish;
  animation-delay: 1s;
  opacity: 1;
  translate: 380px 160px;
}
@keyframes badgeOneReveal {
  0% {
    translate: 0px;
    scale: 0.5;
  }

  60% {
    translate: -560px -260px;
    scale: 1;
    opacity: 1;
  }

  100% {
    translate: -550px -250px;
    scale: 1;
    opacity: 1;
  }
}
@keyframes badgeTwoReveal {
  0% {
    translate: 0px;
    scale: 0.5;
  }

  60% {
    translate: -460px 120px;
    scale: 1;
    opacity: 1;
  }

  100% {
    translate: -450px 110px;
    scale: 1;
    opacity: 1;
  }
}
@keyframes badgeThreeReveal {
  0% {
    translate: 0px;
    scale: 0.5;
  }

  60% {
    translate: 530px -200px;
    scale: 1;
    opacity: 1;
  }

  100% {
    translate: 520px -190px;
    scale: 1;
    opacity: 1;
  }
}
@keyframes badgeFourReveal {
  0% {
    translate: 0px;
    scale: 0.5;
  }

  60% {
    translate: 390px 170px;
    scale: 1;
    opacity: 1;
  }

  100% {
    translate: 380px 160px;
    scale: 1;
    opacity: 1;
  }
}
@keyframes badgeOneVanish {
  0% {
    translate: -550px -250px;
    scale: 1;
    opacity: 1;
  }

  100% {
    translate: 0px;
    opacity: 0;
    scale: 0.5;
  }
}
@keyframes badgeTwoVanish {
  0% {
    translate: -450px 110px;
    scale: 1;
    opacity: 1;
  }

  100% {
    translate: 0px;
    scale: 0.5;
    opacity: 0;
  }
}
@keyframes badgeThreeVanish {
  0% {
    translate: 520px -190px;
    scale: 1;
    opacity: 1;
  }

  100% {
    translate: 0px;
    scale: 0.5;
    opacity: 0;
  }
}
@keyframes badgeFourVanish {
  0% {
    translate: 380px 160px;
    scale: 1;
    opacity: 1;
  }

  100% {
    translate: 0px;
    scale: 0.5;
    opacity: 0;
  }
}

.slideContentContainer {
  max-height: 480px;
  min-height: 480px;
  min-width: 1348px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 54px;
}

.optionsContainer {
  max-height: 520px;
  min-height: 480px;
  width: 500px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  opacity: 0;
  animation-delay: 2s;
  animation-duration: 0.4s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;
}
.optionsReveal {
  animation-name: optionsReveal;
}
.optionsVanish {
  animation-delay: 0s;
  animation-name: optionsVanish;
}
@keyframes optionsReveal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes optionsVanish {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.optionContainer {
  width: 100%;
  height: 100px;
  overflow: hidden;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  transition: all ease-in 1s;
  background-color: black;
}
.option {
  position: relative;
  width: 100%;
  padding: 32px;
  padding-top: 28px;
  cursor: pointer;
  display: flex;
  gap: 16px;
  transition-duration: 0.2s;
  transition-property: background-color;
}
.option:hover {
  background-color: rgba(255, 255, 255, 0.1);
}
.optionSelected {
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.1) -4.37%,
    rgba(255, 255, 255, 0.01) 103.75%
  );
}
.optionSelected:hover {
  background-color: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.1) -4.37%,
    rgba(255, 255, 255, 0.01) 103.75%
  );
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.1) -4.37%,
    rgba(255, 255, 255, 0.01) 103.75%
  );
}
.optionGreenSign {
  position: absolute;
  left: 0px;
  bottom: 0px;
  height: 8px;
  min-width: 500px;
  background-color: #b9fc00;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.optionDescription {
  opacity: 0;
}
.openOptionTypeOne {
  animation-name: openOptionTypeOne;
  animation-duration: 0.4s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
}
.closeOptionTypeOne {
  animation-name: closeOptionTypeOne;
  animation-duration: 0.4s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
}
@keyframes openOptionTypeOne {
  0% {
    height: 88px;
  }
  100% {
    height: auto;
  }
}
@keyframes closeOptionTypeOne {
  0% {
    height: auto;
  }
  100% {
    height: 88px;
  }
}
.openOptionTypeTwo {
  animation-name: openOptionTypeTwo;
  animation-duration: 0.4s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
}
.closeOptionTypeTwo {
  animation-name: closeOptionTypeTwo;
  animation-duration: 0.4s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
}
@keyframes openOptionTypeTwo {
  0% {
    height: 88px;
  }
  100% {
    height: auto;
  }
}
@keyframes closeOptionTypeTwo {
  0% {
    height: auto;
  }
  100% {
    height: 88px;
  }
}
.openOptionTypeThree {
  animation-name: openOptionTypeThree;
  animation-duration: 0.4s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
}
.closeOptionTypeThree {
  animation-name: closeOptionTypeThree;
  animation-duration: 0.4s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
}
@keyframes openOptionTypeThree {
  0% {
    height: 88px;
  }
  100% {
    height: 260px;
  }
}
@keyframes closeOptionTypeThree {
  0% {
    height: 260px;
  }
  100% {
    height: 88px;
  }
}

.slidesContainer {
  position: relative;
  bottom: -480px;
  left: calc(50% - 408px);
  scale: 1.4;
  filter: blur(4px);
  min-width: 816px;
  min-height: 480px;
  display: flex;
  align-items: center;
  position: absolute;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  animation-duration: 2s;
}
.slidesContainerReveal {
  animation-name: slidesContainerReveal;
}
.slidesContainerVanish {
  animation-name: slidesContainerVanish;
  animation-duration: 1s;
}
@keyframes slidesContainerReveal {
  0% {
    scale: 1.4;
    bottom: -480px;
    filter: blur(4px);
    left: calc(50% - 408px);
  }
  50% {
    scale: 1.4;
    bottom: calc(50% - 280px);
    filter: blur(4px);
    left: calc(50% - 408px);
  }
  100% {
    scale: 1;
    bottom: calc(50% - 240px);
    filter: blur(0px);
    left: calc(50% - 146px);
  }
}
@keyframes slidesContainerVanish {
  0% {
    scale: 1;
    bottom: calc(50% - 240px);
    filter: blur(0px);
    left: calc(50% - 146px);
  }
  100% {
    scale: 1.4;
    bottom: -480px;
    filter: blur(4px);
    left: calc(50% - 408px);
  }
}

.imageContainer {
  min-width: 767px;
  min-height: 480px;
  display: flex;
  align-items: center;
  animation-duration: 0.4s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  animation-delay: 2s;
}
.imageOne {
  z-index: 1;
  position: absolute;
  left: -27px;
  left: 0px;
  scale: 0.93;
  filter: blur(4px);
}
.imageTwo {
  z-index: 2;
  position: absolute;
  left: 12px;
  left: 16px;
  scale: 0.97;
  filter: blur(4px);
}
.imageThree {
  z-index: 3;
  position: absolute;
  left: 46px;
  left: 24px;
}
.imageOneReveal {
  animation-name: imageOneReveal;
}
.imageTwoReveal {
  animation-name: imageTwoReveal;
}
.imageThreeReveal {
  animation-name: imageThreeReveal;
}
.imageOneVanish {
  animation-name: imageOneVanish;
  animation-delay: 0s;
}
.imageTwoVanish {
  animation-name: imageTwoVanish;
  animation-delay: 0s;
}
.imageThreeVanish {
  animation-name: imageThreeVanish;
  animation-delay: 0s;
}
@keyframes imageOneReveal {
  0% {
    left: 0px;
  }
  100% {
    left: -27px;
  }
}
@keyframes imageTwoReveal {
  0% {
    left: 16px;
  }
  100% {
    left: 12px;
  }
}
@keyframes imageThreeReveal {
  0% {
    left: 24px;
  }
  100% {
    left: 46px;
  }
}
@keyframes imageOneVanish {
  0% {
    left: -27px;
  }
  100% {
    left: 0px;
  }
}
@keyframes imageTwoVanish {
  0% {
    left: 12px;
  }
  100% {
    left: 16px;
  }
}
@keyframes imageThreeVanish {
  0% {
    left: 46px;
  }
  100% {
    left: 24px;
  }
}
.slideFromOneToTwoImageOne {
  animation-delay: 0s;
  animation-name: slideFromOneToTwoImageOne;
}
.slideFromOneToTwoImageTwo {
  animation-delay: 0s;
  animation-name: slideFromOneToTwoImageTwo;
}
.slideFromOneToTwoImageThree {
  animation-delay: 0s;
  animation-name: slideFromOneToTwoImageThree;
}
@keyframes slideFromOneToTwoImageOne {
  0% {
    left: -27px;
    scale: 0.93;
  }
  100% {
    left: 12px;
    scale: 0.97;
  }
}
@keyframes slideFromOneToTwoImageTwo {
  0% {
    left: 12px;
    scale: 0.97;
  }
  100% {
    left: 46px;
    scale: 1;
    filter: blur(0px);
  }
}
@keyframes slideFromOneToTwoImageThree {
  0% {
    left: 46px;
    scale: 1;
  }
  100% {
    translate: 5000px 0px;
  }
}
.slideFromOneToThreeImageOne {
  animation-delay: 0s;
  animation-name: slideFromOneToThreeImageOne;
}
.slideFromOneToThreeImageTwo {
  animation-delay: 0s;
  animation-name: slideFromOneToThreeImageTwo;
}
.slideFromOneToThreeImageThree {
  animation-delay: 0s;
  animation-name: slideFromOneToThreeImageThree;
}
@keyframes slideFromOneToThreeImageOne {
  0% {
    left: -27px;
    scale: 0.93;
  }
  100% {
    left: 46px;
    scale: 1;
    filter: blur(0px);
  }
}
@keyframes slideFromOneToThreeImageTwo {
  0% {
    left: 12px;
    scale: 0.97;
  }
  100% {
    translate: 5000px 0px;
  }
}
@keyframes slideFromOneToThreeImageThree {
  0% {
    left: 46px;
    scale: 1;
  }
  100% {
    translate: 6000px 0px;
  }
}
.slideFromTwoToOneImageOne {
  animation-delay: 0s;
  animation-name: slideFromTwoToOneImageOne;
}
.slideFromTwoToOneImageTwo {
  animation-delay: 0s;
  animation-name: slideFromTwoToOneImageTwo;
}
.slideFromTwoToOneImageThree {
  animation-delay: 0s;
  animation-name: slideFromTwoToOneImageThree;
}
@keyframes slideFromTwoToOneImageOne {
  0% {
    left: 12px;
    scale: 0.97;
  }
  100% {
    left: -27px;
    scale: 0.93;
  }
}
@keyframes slideFromTwoToOneImageTwo {
  0% {
    left: 46px;
    scale: 1;
    filter: blur(0px);
  }
  100% {
    left: 12px;
    scale: 0.97;
    filter: blur(4px);
  }
}
@keyframes slideFromTwoToOneImageThree {
  0% {
    translate: 5000px 0px;
  }
  100% {
    left: 46px;
    scale: 1;
    filter: blur(0px);
  }
}
.slideFromTwoToThreeImageOne {
  animation-delay: 0s;
  animation-name: slideFromTwoToThreeImageOne;
}
.slideFromTwoToThreeImageTwo {
  animation-delay: 0s;
  animation-name: slideFromTwoToThreeImageTwo;
}
.slideFromTwoToThreeImageThree {
  animation-delay: 0s;
  animation-name: slideFromTwoToThreeImageThree;
}
@keyframes slideFromTwoToThreeImageOne {
  0% {
    left: 12px;
    scale: 0.97;
  }
  100% {
    left: 46px;
    scale: 1;
    filter: blur(0px);
  }
}
@keyframes slideFromTwoToThreeImageTwo {
  0% {
    left: 46px;
    scale: 1;
    filter: blur(0px);
  }
  100% {
    translate: 5000px 0px;
  }
}
@keyframes slideFromTwoToThreeImageThree {
  0% {
    translate: 5000px 0px;
  }
  100% {
    translate: 5000px 0px;
  }
}
.slideFromThreeToOneImageOne {
  animation-delay: 0s;
  animation-name: slideFromThreeToOneImageOne;
}
.slideFromThreeToOneImageTwo {
  animation-delay: 0s;
  animation-name: slideFromThreeToOneImageTwo;
}
.slideFromThreeToOneImageThree {
  animation-delay: 0s;
  animation-name: slideFromThreeToOneImageThree;
}
@keyframes slideFromThreeToOneImageOne {
  0% {
    left: 46px;
    scale: 1;
    filter: blur(0px);
  }
  100% {
    left: -27px;
    scale: 0.93;
    filter: blur(4px);
  }
}
@keyframes slideFromThreeToOneImageTwo {
  0% {
    translate: 5000px 0px;
  }
  100% {
    left: 12px;
    scale: 0.97;
    filter: blur(4px);
  }
}
@keyframes slideFromThreeToOneImageThree {
  0% {
    translate: 6000px 0px;
  }
  100% {
    left: 46px;
  }
}
.slideFromThreeToTwoImageOne {
  animation-delay: 0s;
  animation-name: slideFromThreeToTwoImageOne;
}
.slideFromThreeToTwoImageTwo {
  animation-delay: 0s;
  animation-name: slideFromThreeToTwoImageTwo;
}
.slideFromThreeToTwoImageThree {
  animation-delay: 0s;
  animation-name: slideFromThreeToTwoImageThree;
}
@keyframes slideFromThreeToTwoImageOne {
  0% {
    left: 46px;
    scale: 1;
    filter: blur(0px);
  }
  100% {
    left: 12px;
    scale: 0.97;
    filter: blur(4px);
  }
}
@keyframes slideFromThreeToTwoImageTwo {
  0% {
    translate: 5000px 0px;
  }
  100% {
    left: 46px;
    scale: 1;
    filter: blur(0px);
  }
}
@keyframes slideFromThreeToTwoImageThree {
  0% {
    translate: 6000px 0px;
  }
  100% {
    translate: 6000px 0px;
  }
}

.closeButtonContainer {
  position: absolute;
  z-index: 2000;
  top: 32px;
  right: 32px;
  display: flex;
  align-items: center;
  gap: 12px;
}
.closeButton {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid white;
  border-radius: 4px;
  transition-property: background-color;
  transition-duration: 0.1s;
  transition-timing-function: ease-in;
  cursor: pointer;
}
.closeButton:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.backToTopButton {
  position: absolute;
  bottom: 32px;
  right: 32px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
  gap: 4px;
  border: none;
  border-radius: 20px;
  background-color: #b9fc00;
  padding-right: 16px;
  padding-left: 16px;
  animation-duration: 0.4s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;
  transition-property: background-color;
  transition-duration: 0.1s;
  transition-timing-function: ease-in;
  cursor: pointer;
  opacity: 0;
}
.backToTopButton:hover {
  background-color: #b9fc00e0;
}
.backToTopButtonReveal {
  animation-name: backToTopButtonReveal;
  animation-delay: 2s;
}
.backToTopButtonVanish {
  animation-name: backToTopButtonVanish;
}
@keyframes backToTopButtonReveal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes backToTopButtonVanish {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.openCheckingAccountConfirmationModal {
  width: 480px;
  height: 416px;
  position: absolute;
  z-index: 1000;
  top: calc(50% - 416px / 2);
  left: calc(50% - 480px / 2);
  padding: 20px;
  border-radius: 12px;
  animation-timing-function: ease-out;
  animation-duration: 0.8s;
  animation-fill-mode: forwards;
  background: #2b2b2a;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  opacity: 0;
}
.openCheckingAccountConfirmationModalInit {
  animation-name: openCheckingAccountConfirmationModalInit;
}
.openCheckingAccountConfirmationModalOpen {
  animation-name: openCheckingAccountConfirmationModalOpen;
}
.openCheckingAccountConfirmationModalClose {
  animation-name: openCheckingAccountConfirmationModalClose;
}
@keyframes openCheckingAccountConfirmationModalInit {
  0% {
    opacity: 0;
    display: none;
  }
  100% {
    opacity: 0;
    display: none;
  }
}
@keyframes openCheckingAccountConfirmationModalOpen {
  0% {
    opacity: 0;
    display: none;
  }
  100% {
    opacity: 1;
    display: flex;
  }
}
@keyframes openCheckingAccountConfirmationModalClose {
  0% {
    opacity: 1;
    display: flex;
  }
  100% {
    opacity: 0;
    display: none;
  }
}

.confirmationImageOneContainer {
  width: 200px;
  height: 200px;
}
.confirmOpenAccountButton {
  height: 40px;
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 20px;
  padding-left: 16px;
  padding-right: 16px;
  background-color: #ffffff40;
  text-wrap: nowrap;
  border: none;
  overflow: hidden;
  transition-duration: 0.4s;
  transition-property: background-color;
  cursor: pointer;
}
.confirmOpenAccountButton:hover {
  background-color: #ffffff60;
}
