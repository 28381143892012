.main-container {
  display: flex;
  align-items: center;
  position: relative;
}

.content-container {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
}

.inner-content-container {
  display: flex;
  width: 100%;
}

.column-1 {
  display: flex;
  flex-direction: column;
  width: 5%;
}

.column-2 {
  display: flex;
  flex-direction: column;
  width: 35%;
}

.column-3 {
  display: flex;
  flex-direction: column;
  width: 60%;
  align-items: flex-end;
}

.info-title-text {
  color: #102c57;
}

.thin-text {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: #333334;
}

.thick-text {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: #102c57;
}
