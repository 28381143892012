.modal {
  z-index: 1301;
  width: 440px;
  margin-left: 30px;
}
.bg {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 32px;
}
.outer {
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 438px;
}
.title {
  color: var(--Primary-Black, #000);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; /* 133.333% */
}
.item {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.subtitle {
  color: var(--Neutral-800, #6c6c71);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}
.card {
  display: flex;
  gap: 16px;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  border: 1px solid var(--Neutral-500, #e6e6e8);
  background: var(--Neutral-200, #fcfcfc);
  padding: 12px;
}
.card-selected {
  border: 1px solid var(--Neutral-900, #343434);
}
.card-inner {
  display: flex;
  align-items: center;
  gap: 16px;
}
.card:hover {
  background-color: #f1f1f1;
  /* box-shadow: inset 0px 0px 0px 1px #343434; */
}
.card-icon {
  border-radius: 8px;
  background: var(--Neutral-900, #343434);
  display: flex;
  width: 32px;
  height: 32px;
  padding: 6px;
  justify-content: center;
  align-items: center;
}
.card-text {
  overflow: hidden;
  color: var(--Neutral-800, #6c6c71);
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}
.recipient {
  display: flex;
  /* height: 64px; */
  padding: 12px 12px;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid var(--Neutral-500, #e6e6e8);
  /* max-width: 438px; */
}
.truncated-text {
  max-width: 234px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 12px;
}
.recipient-details {
  display: flex;
  flex: 1;
  gap: 12px;
  align-items: center;
}
.warning-modal {
  width: 398px;
  margin-left: 52px;
  /* height: 344px; */
}