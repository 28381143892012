.content {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 32px 20px;
}

.option {
  position: relative;
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
  height: 72px;
  padding: 0px 16px;
  border: 1px solid var(--neutral500);
  border-radius: 8px;
  cursor: pointer;
}
.option:hover {
  background-color: var(--neutral400);
}

.iconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  background-color: var(--neutral900);
  border-radius: 8px;
}

.typeContainer {
  display: flex;
  align-items: center;
  gap: 8px;
}
