.disabled {
  /* background-color: rgba(0, 0, 0, 0.05); Light grey background */
  pointer-events: none; /* Makes the card non-clickable */
  opacity: 0.7; /* Dims the card */
  position: relative;
}

.disabled::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  pointer-events: none;
  border-radius: 4px;
  background: #e6e6e8;
  opacity: 0.3;
}
