.pulse {
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background-color: var(--greenB9);
  animation: pulse-animation 1.2s infinite linear;
}

@keyframes pulse-animation {
  0% {
    background-color: rgba(185, 252, 0, 1);
    box-shadow:
      0 0 0 0px rgba(185, 252, 0, 0),
      0 0 0 0px rgba(185, 252, 0, 0.3);
  }
  20% {
    box-shadow:
      0 0 0 0px rgba(185, 252, 0, 0.1),
      0 0 0 3px rgba(185, 252, 0, 0.3);
  }
  40% {
    box-shadow:
      0 0 0 3px rgba(185, 252, 0, 0.2),
      0 0 0 9px rgba(185, 252, 0, 0.3);
  }
  60% {
    background-color: rgba(185, 252, 0, 0.8);
    box-shadow:
      0 0 0 6px rgba(185, 252, 0, 0.6),
      0 0 0 15px rgba(185, 252, 0, 0.15);
  }
  80% {
    box-shadow:
      0 0 0 15px rgba(185, 252, 0, 0.2),
      0 0 0 13px rgba(185, 252, 0, 0.1);
  }
  85% {
    box-shadow:
      0 0 0 15px rgba(185, 252, 0, 0.2),
      0 0 0 10px rgba(185, 252, 0, 0.05);
  }
  90% {
    box-shadow:
      0 0 0 15px rgba(185, 252, 0, 0.1),
      0 0 0 10px rgba(185, 252, 0, 0);
  }
  100% {
    background-color: rgba(185, 252, 0, 1);
    box-shadow:
      0 0 0 13px rgba(185, 252, 0, 0),
      0 0 0 0px rgba(185, 252, 0, 0);
  }
}

