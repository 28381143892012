.danamonReleaseAdvertisement {
  width: 100%;
  height: 60px;
  display: flex;
  gap: 8px;
  padding-top: 10px;
  padding-left: 16px;
  background: linear-gradient(45.6deg, #000000 60.13%, #eef0e6 184.5%);
}

.textContainer {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
