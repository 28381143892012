.more-icon {
  background-color: "transparent";
}

.more-icon:hover {
  background-color: #E6E6E8;
  transition: "all 0.4s ease-in-out"
}

.option-div-transition {
  background-color: white;
}

.option-div-transition:hover {
  transition: all 0.2s ease;
  background-color: #F1F1F1;
}

.month-selection-pill {
  transition: all ease-in-out 0.1s;
  border: 1px solid #E6E6E8
}

.month-selection-pill:hover {
  background-color: #F1F1F1;
  border: 1px solid black
}

.copy-icon {
  border: 1px solid #E6E6E8;
  border-radius: 2px;
  }

.copy-icon:hover {
background-color: #FFFFFF;
box-shadow: 0px 8px 16px -4px #16223314;

}

.month-selection-grid {
  display: grid;
  column-gap: 22px;
  row-gap: 32px;
  grid-template-columns: repeat(4, 1fr);
}

.month-selection-item:hover {
  background-color: #F1F1F1;
}