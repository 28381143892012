.new-tag {
  display: flex;
  padding: 2px 8px;
  background-color: var(--greenB9);
  border-radius: 4px;
  position: relative;
  cursor: default;
}

.soon-tag {
  display: flex;
  padding: 2px 8px;
  background-color: #F1F1F1;
  border-radius: 4px;
  position: relative;
  cursor: default;
}

.info {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
}
.info > div:nth-child(1) {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.info > div:nth-child(2) {
  padding-right: 10px;
}
