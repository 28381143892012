.content-container {
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.empty-state-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.content-section-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.filter-container {
  padding: 24px 32px 0px 32px;
}

.title-font {
  font-size: 24px;
  line-height: 32px;
  color: var(--neutral900);
  margin-left: 32px;
}

.empty-font {
  font-size: 20px;
  line-height: 24px;
  color: var(--neutral900);
}

.empty-sub-font {
  font-size: 14px;
  line-height: 20px;
  color: var(--neutral800);
  margin: 0
} 

.white-font {
  color: var(--neutral100);
}

.cancel-font {
  color: var(--neutral500);
}

.title-content-divider {
  border-top: 1px solid var(--neutral500);
}

.dropdown-table-outer-container {
  border: 1px solid var(--neutral500);
  border-radius: 8px;
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease-in;
  overflow-y: hidden;
  overflow-x: visible;
}

.dropdown-table-item-container {
  display: flex;
  justify-content: space-between;
  padding: 0px 12px 0px 8px;
  height: 40px;
  border-bottom: 1px solid var(--neutral500);
  cursor: pointer;
}

.dropdown-table-item-container_no-border {
  display: flex;
  justify-content: space-between;
  padding: 0px 12px 0px 8px;
  height: 40px;
  cursor: pointer;
}

.dropdown-table-item-left-container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.dropdown-table-item-text-left-container {
  height: 20px;
  width: 40px;
  display: flex;
  justify-content: center;
}

.dropdown-table-item-text-right-container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.mark-as-read-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  border: 1px solid var(--neutral500);
  border-radius: 4px;
  background-color: white;
  position: relative;
}

.description-container {
  display: flex;
  align-items: center;
  gap: 12px;
}

.ellipsis-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: calc(100% - 36px);
}

.see-more-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  height: 40px;
  cursor: pointer;
}

.mark-as-read-summary-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.mark-as-read-summary-transactions-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.mark-as-read-summary-buttons-container {
  display: flex;
  gap: 12px;
}

.number-indicator {
  padding: 0px 8px;
  border-radius: 4px;
  font-size: 12px;
  line-height: 16px;
  color: var(--neutral100);
  background-color: var(--neutral900);
  height: 20px;
}

.total-amount-td {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 0px !important;
}

.attachment-td {
  display: flex;
  gap: 9px;
}