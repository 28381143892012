.search-dropdown {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 8px;
  border: 1px solid var(--neutral400);
  border-radius: 8px;
  overflow-y: hidden;
  height: 384px;
  width: 100%;
  box-shadow:
    0px 16px 24px 0px rgba(22, 34, 51, 0.08),
    0px 4px 8px -4px rgba(22, 34, 51, 0.08);
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: white;
  animation-duration: 0.2s;
  animation-fill-mode: forwards;
  cursor: default;
  z-index: 100;
}
.opened {
  animation-name: role-down;
}
.closed {
  animation-name: role-up;
}
@keyframes role-down {
  0% {
    height: 0px;
    opacity: 0;
  }

  20% {
    opacity: 1;
  }

  100% {
    height: 384px;
    opacity: 1;
  }
}
@keyframes role-up {
  0% {
    height: 384px;
    opacity: 1;
  }

  80% {
    opacity: 0;
  }

  100% {
    height: 0px;
    opacity: 0;
  }
}
