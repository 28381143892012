.category-list-container {
  max-height: 312px;
  min-height: 312px;
  overflow-x: hidden;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.category-list-container::-webkit-scrollbar {
  display: none;
}

.category-list {
  display: flex;
  flex-direction: column;
}
