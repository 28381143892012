.category-team-selection {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.category-team-selection-input {
  min-height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 12px;
  padding-left: 12px;
  border: 1px solid var(--neutral500);
  border-radius: 4px;
  cursor: pointer;
  position: relative;
}

.left-section {
  display: flex;
  align-items: center;
  gap: 8px;
}

.category-team-label {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--neutral400);
  border-right: 1px solid var(--neutral500);
}

.team {
  height: 48px;
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 0px 8px;
  border-radius: 4px;
  transition: background-color 0.1s ease-in;
  cursor: pointer;
}
.team:hover {
  background-color: var(--neutral400);
}

.team-list-container {
  max-height: 312px;
  min-height: 312px;
  overflow-x: hidden;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.team-list-container::-webkit-scrollbar {
  display: none;
}

.selected-teams-container {
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.selected-teams-container_v2 {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding: 12px;
  border: 1px solid var(--neutral500);
  border-radius: 4px;
}

.selected-team {
  height: 20px;
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 0px 8px;
  border-radius: 2px;
  background-color: var(--neutral400);
}

.opened {
  animation-name: role-down;
}
.closed {
  animation-name: role-up;
}

@keyframes role-down {
  0% {
    height: 384px;
    opacity: 1;
  }

  100% {
    height: 384px;
    opacity: 1;
  }
}
@keyframes role-up {
  0% {
    height: 0px;
    opacity: 0;
  }

  100% {
    height: 0px;
    opacity: 0;
  }
}
