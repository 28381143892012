.category-intro-modal {
  min-width: 516px;
}

.category-intro-content {
  display: flex;
  flex-direction: column;
  padding: 20px;
  padding-bottom: 32px;
}
