
/* parent */
.empty-state-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .content-section-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  
  .filter-container {
    padding: 24px 32px 0px 32px;
  }
  
  .title-font {
    font-size: 24px;
    line-height: 32px;
    color: var(--neutral900);
    margin-left: 32px;
  }
  
  .empty-font {
    font-size: 20px;
    line-height: 24px;
    color: var(--neutral900);
  }
  
  .empty-sub-font {
    font-size: 14px;
    line-height: 20px;
    color: var(--neutral800);
    margin: 0
  } 
  
/* components */
.notif-item-container {
    padding: 16px; 
    border-bottom: 1px solid #E6E6E8; 
    background: #fff; 
    display: flex; 
    flex-direction: row; 
    justify-content: space-between;
    cursor: pointer;
    gap: 8px
}

.notif-item {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.notif-item-container:hover {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0.05) 100%), #FFF;
}

.notif-item-unread {
    background: linear-gradient(0deg, rgba(155, 222, 184, 0.15) 0%, rgba(155, 222, 184, 0.15) 100%), #FFF;
}

.notif-item-unread:hover {
    border-bottom: 1px solid var(--neutral-500, #E6E6E8);
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0.05) 100%), linear-gradient(0deg, rgba(155, 222, 184, 0.15) 0%, rgba(155, 222, 184, 0.15) 100%), #FFF;
}

.check-icon {
    border-radius: 4px;
    border: 1px solid var(--neutral-500, #E6E6E8);
    background: var(--primary-white, #FFF);
}
