.main-container {
  display: flex;
  gap: 3px;
}

.text-blue {
  color: #2c4a77;
}

.text-gray-striked {
  color: #bbbbc0;
  text-decoration: line-through;
}
