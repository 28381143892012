.category-setting-modal {
  min-width: 516px;
}

.content {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.title-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.title-container > div:nth-child(1) {
  display: flex;
  align-items: center;
  gap: 8px;
}

.preference-container {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.preference {
  display: flex;
  gap: 16px;
  padding: 16px;
  border: 1px solid var(--neutral400);
  border-radius: 8px;
  cursor: pointer;
  position: relative;
}
.preference > div:nth-child(1) {
  min-width: 180px;
  height: 120px;
}
.preference > div:nth-child(2) {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.switch {
  height: 16px;
  width: 32px;
  display: flex;
  justify-content: flex-start;
  border-radius: 8px;
  background-color: var(--neutral500);
  padding: 2px;
  cursor: pointer;
}
.switch-dot {
  width: 12px;
  height: 12px;
  border-radius: 6px;
  background-color: white;
}

.divider {
  width: 100%;
  border-top: 1px solid var(--neutral500);
}
