.category-setup-modal {
  min-width: 516px;
}

.category-setup-content {
  display: flex;
  flex-direction: column;
  padding: 20px;
  padding-bottom: 32px;
  gap: 16px;
}

.category-preference-container {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.category-preference {
  display: flex;
  gap: 16px;
  padding: 16px;
  border: 1px solid var(--neutral400);
  border-radius: 8px;
  cursor: pointer;
  position: relative;
}

.link {
  font-family: GothamMedium;
  text-decoration: underline;
  cursor: pointer;
}
.link:hover {
  color: var(--neutral800);
}
