.category-selection {
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
  margin-bottom: 32px;
}

.category-selection_simple {
  width: 236px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
  margin-bottom: 32px;
}

.category-selection_details {
  display: flex;
  gap: 8px;
  position: relative;
  margin-bottom: 32px;
}

.category-selection_details_modal_edit {
  display: flex;
  gap: 8px;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}

.label {
  display: flex;
  align-items: center;
  gap: 4px;
}

.input {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 12px;
  border: 1px solid var(--neutral500);
  border-radius: 4px;
  cursor: pointer;
}
.input > div:nth-child(1) {
  height: 40px;
  display: flex;
  align-items: center;
  gap: 4px;
}

.input:hover {
  border: 1px solid var(--neutral600);
}

.input_simple {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  padding: 0px 4px;
  border: 1px solid var(--neutral500);
  border-radius: 4px;
  cursor: pointer;
}
.input_simple > div:nth-child(1) {
  height: 40px;
  display: flex;
  align-items: center;
  gap: 4px;
}

.input_details {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 16px;
  border: 1px solid var(--neutral500);
  border-radius: 4px;
  cursor: pointer;
}
.input_details .left-section {
  height: 58px;
  display: flex;
  align-items: center;
  gap: 16px;
}
.input_details .text-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.input_details .title {
  display: flex;
  align-items: center;
}

.input_details_modal_edit {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--neutral500);
  cursor: pointer;
}

.input_details_modal_edit .left-section {
  height: 34px;
  display: flex;
  align-items: center;
  gap: 16px;
}
.input_details_modal_edit .text-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.input_details_modal_edit .title {
  display: flex;
  align-items: center;
}

.input_category_default_background {
  border-bottom: 1px solid #e6e6e8;
}

.input_category_background_changed {
  border-bottom: 1px solid #bbbbc0;
}

.input_category_error {
  border-bottom: 1px solid #e73126;
}

.new-tag {
  height: 16px;
  display: flex;
  align-items: center;
  padding: 0px 8px;
  background-color: var(--greenB9);
  border-radius: 4px;
}
