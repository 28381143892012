.categoryWelcomingModal {
  min-width: 600px;
}

.updateContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 20px 20px 32px 20px;
}

.update {
  display: flex;
  gap: 16px;
  border: 1px solid var(--neutral500);
  border-radius: 8px;
  padding: 16px;
}

.imageContainer {
  min-width: 180px;
  min-height: 100px;
  position: relative;
}

.textContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.leftHeader {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.link {
  font-family: GothamMedium;
  text-decoration: underline;
  cursor: pointer;
}
.link:hover {
  color: var(--neutral800);
}
