.categoryColorSelection {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.categoryColorSelectionInput {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 12px;
  background-color: var(--neutral200);
  border: 1px solid var(--neutral500);
  border-radius: 4px;
  cursor: pointer;
  position: relative;
}

.leftSection {
  display: flex;
  align-items: center;
  gap: 8px;
}

.categoryColorLabel {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--neutral400);
  border-right: 1px solid var(--neutral500);
}

.color {
  width: 20px;
  height: 20px;
  border-radius: 10px;
}
