.category-header-selection-dropdown {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 8px;
  box-shadow:
    0px 16px 24px 0px #16223314,
    0px 4px 8px -4px #16223314;
}

.header {
  padding-top: 8px;
  padding-right: 8px;
  padding-left: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.headers-container {
  display: flex;
  flex-direction: column;
}

.header-container {
  display: flex;
  height: 44px;
  align-items: center;
  padding-left: 12px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.1s ease-in;
}
.header-container:hover {
  background-color: var(--neutral500);
}

.icons-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.icons {
  display: flex;
  justify-content: space-between;
  gap: 15px;
}

.icon-container {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 16px;
}

.horizontal-line {
  width: 100%;
  border-top: 1px solid var(--neutral500);
}

.opened {
  animation-name: role-down;
}
.closed {
  animation-name: role-up;
}

@keyframes role-down {
  0% {
    height: 0px;
    opacity: 0;
  }

  20% {
    opacity: 1;
  }

  100% {
    height: 320px;
    opacity: 1;
  }
}
@keyframes role-up {
  0% {
    height: 320px;
    opacity: 1;
  }

  80% {
    opacity: 0;
  }

  100% {
    height: 0px;
    opacity: 0;
  }
}
