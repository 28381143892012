.outer {
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 438px;
}
.add-more-btn {
  display: flex;
  flex-direction: row;
  gap: 16px;
  justify-content: space-between;
  padding: 12px;
  border-radius: 8px;
  background: var(--Primary-Black, #000);
  color: white;
  width: 100%;
  flex: 1;
  /* background: linear-gradient(to right, #000000, #B9FC00) */
}
.add-more-btn:hover {
  background: var(--Primary-Black, #000);
}
.green-circle {
  position: absolute;
  right: 0;
  width: 150px; /* Adjust size as needed */
  height: 150px;
  /* background-color: rgba(185, 252, 0, 0.40); */
  border-radius: 50%; /* This makes it a circle */
  /* top: 70%; */
  transform: translateY(-60%) translateX(40%) scaleX(-1); /* Center it vertically */
  /* fill: radial-gradient(50% 50% at 50% 50%, rgba(185, 252, 0, 0.40) 0%, rgba(185, 252, 0, 0.00) 100%); */
}
.modal-confirm {
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
}
.info-container {
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid var(--Neutral-500, #e6e6e8);
  background: var(--Neutral-100, #fff);
}
.info-upper-card {
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}
.upper-text-container {
  display: flex;
  gap: 4px;
  flex-direction: column;
}
.info-lower-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}
.info-lower-card-child {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}
.modal-confirm-btn-container {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 20px 0;
  gap: 16px;
}
.multi-email-textfield {
  display: flex;
  padding: 10px 12px;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid var(--Neutral-600, #bbbbc0);
}
.simple-centered-modal {
  width: 440px;
  margin-left: 30px;
}
.modal-header {
  position: relative;
  z-index: 100;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 76px;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  border-bottom: 1px solid var(--neutral500);
}

.modal-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  height: 80px;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  border-top: 1px solid var(--neutral500);
}

.close-btn-container {
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--neutral500);
  border-radius: 4px;
  cursor: pointer;
  background-color: var(--neutral100);
}
.close-btn-container:hover {
  background-color: var(--neutral400);
}
.truncated-text {
  /* max-width: 150px; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 12px;
  text-align: right;
}