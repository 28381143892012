.table-container {
  // padding: 8px;
  background-color: white !important;
  border: 1px solid #e6e6e8;
  border-radius: 8px;
  position: relative;
  overflow-x: auto;
  width: fit-content;
  thead {
    th.default-padding {
      padding: 8px; // Default padding for header
    }

    th.no-padding {
      padding: 0; // No padding when `noHeaderPadding` is true
    }
  }

  tbody {
    td {
      padding: 8px; // Body padding remains at 8px
    }
  }
}

.table-container-dumb {
  padding: 8px;
  border-radius: 8px;
  position: relative;
  overflow-x: auto;
  width: fit-content;
}

.float-table-container {
  position: absolute;
  top: -1px;
  right: 0;
  z-index: 10;
  width: fit-content;
  height: 100%;

  .fixed-position {
    position: fixed;
    background-color: white;
    border: 1px solid #e6e6e8;
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;
    padding: 7.5px;
  }

  thead {
    th {
      text-align: center;
    }
  }

  tbody {
    tr {
      td {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &:hover {
        background-color: white !important;
      }
    }
  }
}

table.table-seamless-dumb {
  border-collapse: collapse;
  border-radius: 8px;

  thead {
    tr {
      height: 48px;
      color: inherit;

      &:hover {
        text-decoration: none;
        color: inherit;
        td.action {
          div {
            visibility: visible;
          }
          button {
            visibility: visible;
          }
        }
      }
      th {
        position: sticky;
        top: 0;
        z-index: 2;
        color: #909098;
        font-family: "GothamBook";
        line-height: 16px;
        letter-spacing: 0.2px;
        font-size: 14px;
        vertical-align: middle;
        margin: 0 5px;
        box-shadow: 0 -1px #eaeaea inset;
        padding: 8px;

        &.empty {
          text-align: center;
          vertical-align: middle;
          padding: 20px 0px;
        }
        &.action {
          div {
            visibility: hidden;
          }
          button {
            visibility: hidden;
          }
        }
      }
      th:first-child {
        border-top-left-radius: 8px;
      }
    }
  }
  tbody {
    tr {
      cursor: pointer;
      height: 48px;
      color: inherit;
      border-bottom: 1px solid #e6e6e8;

      &:hover {
        background: #f5f5f5;
        text-decoration: none;
        color: inherit;
        td.action {
          div {
            visibility: visible;
          }
          button {
            visibility: visible;
          }
        }
      }
      td {
        vertical-align: middle;
        height: 64px;
        margin: 0 5px;
        padding: 8px;
        font-family: "GothamBook";
        font-size: 14px;
        line-height: 20px;
        color: #343434;
        //we can use this animation for smooth data presentation but when we open the three dots,
        //the three dots below it somehow get in front of the dropdown option
        // opacity: 0;
        // animation: fadeIn 600ms ease-in 250ms 1 normal forwards;
        &.empty {
          text-align: center;
          vertical-align: middle;
          padding: 20px 0px;
        }
        &.action {
          div {
            visibility: hidden;
          }
          button {
            visibility: hidden;
          }
        }
      }
    }

    tr:last-child {
      border-bottom: none;
    }
  }
}

table.table-seamless {
  border-collapse: collapse;
  border-radius: 8px;

  thead {
    tr {
      height: 48px;
      color: inherit;
      &:hover {
        text-decoration: none;
        color: inherit;
        td.action {
          div {
            visibility: visible;
          }
          button {
            visibility: visible;
          }
        }
      }
      th {
        position: sticky;
        top: 0;
        z-index: 2;
        color: #909098;
        font-family: "GothamBook";
        line-height: 16px;
        letter-spacing: 0.2px;
        font-size: 14px;
        vertical-align: middle;
        margin: 0 5px;
        box-shadow: 0 -1px #eaeaea inset;
        padding: 8px;

        &.empty {
          text-align: center;
          vertical-align: middle;
          padding: 20px 0px;
          background-color: white;
        }
        &.action {
          div {
            visibility: hidden;
          }
          button {
            visibility: hidden;
          }
        }
      }
      th:first-child {
        border-top-left-radius: 8px;
      }
    }
  }
  tbody {
    tr {
      background: white;
      cursor: pointer;
      height: 48px;
      color: inherit;
      border-bottom: 1px solid #e6e6e8;

      &:hover {
        background: #f5f5f5;
        text-decoration: none;
        color: inherit;
        td.action {
          div {
            visibility: visible;
          }
          button {
            visibility: visible;
          }
        }
      }
      td {
        vertical-align: middle;
        height: 64px;
        margin: 0 5px;
        padding: 8px;
        font-family: "GothamBook";
        font-size: 14px;
        line-height: 20px;
        color: #343434;
        //we can use this animation for smooth data presentation but when we open the three dots,
        //the three dots below it somehow get in front of the dropdown option
        // opacity: 0;
        // animation: fadeIn 600ms ease-in 250ms 1 normal forwards;
        &.empty {
          text-align: center;
          vertical-align: middle;
          padding: 20px 0px;
        }
        &.action {
          div {
            visibility: hidden;
          }
          button {
            visibility: hidden;
          }
        }
      }
    }

    tr:last-child {
      border-bottom: none;
    }
  }
}

.table-button {
  border: 1px solid #e6e6e8;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;

  &:hover {
    background-color: #f5f5f5;
  }
}
