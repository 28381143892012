.inactiveDot {
  width: 100%;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dot {
  width: 8px;
  height: 8px;
  background-color: var(--neutral600);
  border-radius: 4px;
}
