.danamonAdvertisementModal {
  width: 463px;
  translate: 10px 0px;
  background-color: black;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.imageContainer {
  width: 333px;
  height: 150px;
}

.textContainer {
  width: 399px;
}

.buttonsContainer {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 16px;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 10px;
}

.buttonOutline {
  width: 50%;
  height: 40px;
  background-color: transparent;
  border: 1px solid white;
  border-radius: 20px;
  transition-duration: 0.2s;
  transition-property: background-color;
}
.buttonOutline:hover {
  background-color: #fff2;
}

.buttonFilled {
  width: 50%;
  height: 40px;
  background-color: #414041;
  border-radius: 20px;
  border: none;
  transition-duration: 0.2s;
  transition-property: background-color;
}
.buttonFilled:hover {
  background-color: #6d6d6d;
}
