.customToggle {
  width: 100%;
  height: 40px;
  display: flex;
  cursor: pointer;
  background-color: #f0f0f0;
  border-radius: 8px;
  border: 1px solid #f1f1f1;
  position: relative;
  overflow: hidden;
  padding: 2px;
  margin-bottom: 32px;
}

.switchBackground {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
}

.switchSlider {
  height: 100%;
  width: 50%;
  background-color: white;
  position: absolute;
  transition: all 0.5s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.label {
  position: absolute;
  width: 50%;
  text-align: center;
  /* pointer-events: none; Make clicks pass through the text */
}
