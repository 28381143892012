@keyframes slideEnterUp {
  from {
    opacity: 0;
    transform: translateY(-40px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideExitUp {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(40px);
  }
}

.slideEnterUp {
  animation: slideEnterUp 0.4s ease forwards;
}

.slideExitUp {
  animation: slideExitUp 0.4s ease forwards;
}

@keyframes slideEnterDown {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0px);
    }
  }
  
  @keyframes slideExitDown {
    from {
      opacity: 1;
      transform: translateY(0px);
    }
    to {
      opacity: 0;
      transform: translateY(10px);
    }
  }

.slideEnterDown {
  animation: slideEnterDown 0.4s ease forwards;
}

.slideExitDown {
  animation: slideExitDown 0.4s ease forwards;
}

@keyframes slideDown {
  from {
    /* max-height: 0; */
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    /* max-height: 500px; */
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideUp {
  from {
    /* max-height: 500px; */
    opacity: 1;
    transform: translateY(0);
  }
  to {
    /* max-height: 0; */
    opacity: 0;
    transform: translateY(-20px);
  }
}

.slideEnterRecipient {
  animation: slideDown 0.5s ease forwards;
  overflow: hidden;
}

.slideExitRecipient {
  animation: slideUp 0.5s ease forwards;
  overflow: hidden;
}


.hidden {
  display: none;
}
